import Mailcheck from 'mailcheck';
import $ from 'jquery';
import 'email-autocomplete';

import { __x } from 'JIX/gettext.js';

const email_common_domains = {
    cwddk: [],
    itjdk: [
        'gmail.com',
        'hotmail.com',
        'yahoo.com',
        'yahoo.dk',
        'live.dk',
        'mail.dk',
        'msn.com',
        'ofir.dk',
        'privat.dk',
        'sol.dk',
        'itu.dk',
        'tdcadsl.dk',
        'mail.tele.dk',
        'stofanet.dk',
        'outlook.com',
        'hotmail.dk',
        'webspeed.dk',
        'email.dk',
        'me.com',
        'outlook.dk',
        'pc.dk',
        'vip.cybercity.dk',
        'post.tele.dk',
        'get2net.dk',
        'live.com',
        'c.dk',
        'mail1.stofanet.dk',
        'it.dk',
        'jubii.dk',
        'adslhome.dk',
    ],
    jobdk: [
        'gmail.com',
        'hotmail.com',
        'live.dk',
        'yahoo.dk',
        'yahoo.com',
        'mail.dk',
        'hotmail.dk',
        'outlook.dk',
        'msn.com',
        'stofanet.dk',
        'ofir.dk',
        'sol.dk',
        'privat.dk',
        'webspeed.dk',
        'me.com',
        'jubii.dk',
        'icloud.com',
        'outlook.com',
        'youmail.dk',
        'mail.tele.dk',
        'email.dk',
        'live.com',
        'forum.dk',
        'godmail.dk',
        'pc.dk',
        'os.dk',
        'c.dk',
        'bbsyd.dk',
        'mail.com',
        'post.tele.dk',
        'vip.cybercity.dk',
        'wp.pl',
        'ymail.com',
        'get2net.dk',
        'tdcadsl.dk',
        'yahoo.co.uk',
        'dbmail.dk',
        'mail.ru',
        'mail-online.dk',
        'profibermail.dk',
        'mac.com',
        'city.dk',
        'comxnet.dk',
        'fibermail.dk',
        'inbox.lv',
        'tiscali.dk',
        'oncable.dk',
        'esenet.dk',
        'ruc.dk',
    ],
    jobno: [
        'gmail.com',
        'hotmail.com',
        'yahoo.com',
        'online.no',
        'live.no',
        'hotmail.no',
        'yahoo.no',
        'outlook.com',
        'wp.pl',
        'msn.com',
        'live.com',
        'icloud.com',
        'me.com',
        'broadpark.no',
        'inbox.lv',
        'mail.ru',
        'live.dk',
        'yahoo.fr',
        'ymail.com',
        'live.se',
        'o2.pl',
        'interia.pl',
        'getmail.no',
        'yahoo.co.uk',
        'yahoo.dk',
        'abv.bg',
        'mail.com',
        'lyse.net',
        'frisurf.no',
        'op.pl',
    ],
    stedk: [
        'gmail.com',
        'hotmail.com',
        'live.dk',
        'yahoo.dk',
        'mail.dk',
        'yahoo.com',
        'ofir.dk',
        'stofanet.dk',
        'hotmail.dk',
        'privat.dk',
        'msn.com',
        'sol.dk',
        'webspeed.dk',
        'mail.tele.dk',
        'jubii.dk',
        'email.dk',
        'forum.dk',
        'youmail.dk',
        'outlook.dk',
        'get2net.dk',
        'me.com',
        'vip.cybercity.dk',
        'tdcadsl.dk',
        'pc.dk',
        'post.tele.dk',
        'os.dk',
        'c.dk',
        'outlook.com',
        'icloud.com',
        'live.com',
    ],
};

const default_options = {
    suggClass: "jix-email-completion",
    // The fifty most common domains among our users
    domains: email_common_domains[window.Stash.common.site]
};

// Check if a DOM element is visible. Equivalent to jQuery's :visible property.
// From: https://github.com/jquery/jquery/blob/main/src/css/hiddenVisibleSelectors.js
function visible(elem) {
    return !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
}

// Enable autocompletion and suggestions for
// common domains on an email input field.
export default function setupEmailField(input) {
    $(input).emailautocomplete(default_options);
    // emailautocomplete places a wrapper around the input element,
    // which causes input to lose any focus it might have had.
    // The following line reestablishes said focus.
    if (input.autofocus) { input.focus(); }

    const suggestion_div = document.createElement('div');
    const inner = document.createElement('div');
    suggestion_div.className = "jix-email-suggestion";
    suggestion_div.appendChild(inner);

    const inputGroup = input.closest('.input-group');
    if (inputGroup) {
        inputGroup.parentNode.insertBefore(suggestion_div, inputGroup.nextElementSibling);
    } else {
        if (input.closest('.form-inline')) {
            suggestion_div.classList.add("jix-email-suggestion-inline");
        }
        input.parentNode.appendChild(suggestion_div);
    }

    const mailcheck_options = {
        domains: email_common_domains[window.Stash.common.site],
        suggested: showSuggestion,
        empty: hideSuggestion,
        distanceFunction: (input, domain) => {
            if (domain.indexOf(input) === 0) {
                return 0;
            } else if (domain === "gmail.com" && /^gmail\./.test(input)) {
                return 0;
            } else {
                return Mailcheck.sift3Distance(input, domain);
            }
        }
    };

    // Find a plausible suggestion, if such exists, to the user's current input.
    function checkForSuggestion() {
        const opts = { ...mailcheck_options };
        $(input).mailcheck(opts);
    }

    // Set up and display the div containing the suggested email.
    function showSuggestion(element, suggestion) {
        if (!/\./.test(suggestion.domain)) {
            // Do not suggest top-level-only domains
            hideSuggestion();
            return;
        }
        const link = document.createElement('a');
        const text_address = suggestion.address.replace(/^(.{15}).+$/, "$1…");
        const text_domain = "<strong>" + suggestion.domain + "</strong>";
        link.innerHTML = text_address + "@" + text_domain;
        link.addEventListener('click', () => {
            input.value = suggestion.full;
            // Force update of autocomplete suggestion
            $(input).data('yz_emailautocomplete').displaySuggestion();
            hideSuggestion();
        });
        // Empty inner for all nodes and references
        while (inner.hasChildNodes()) { inner.removeChild(inner.lastChild); }
        inner.innerHTML = __x("Mente du {email}?", { email: "<span></span>" });
        inner.querySelector('span').replaceWith(link);
        $(suggestion_div).slideDown();
        suggestion_div.parentNode.classList.add('has-warning');
    }

    // Hide suggestion div.
    function hideSuggestion() {
        $(suggestion_div).slideUp();
        suggestion_div.parentNode.classList.remove('has-warning');
    }

    let listeningForMove = false;
    // Trigger a check for a suggestion when moving the mouse after new user input.
    input.addEventListener('input', () => {
        hideSuggestion();
        if (!listeningForMove) {
            listeningForMove = true;
            document.querySelector('body').addEventListener('mousemove', () => {
                checkForSuggestion();
                listeningForMove = false;
            }, { once: true });
        }
    });
    // Trigger a check for a suggestion when the input field loses focus.
    input.addEventListener('blur', () => {
        if (!visible(suggestion_div)) { checkForSuggestion(); }
    });
}
